var firebaseConfig = {
	apiKey: "AIzaSyDRoyDqAmyNACmNytRSe1lgjHRIhfgOs74",
	authDomain: "chgi-8a982.firebaseapp.com",
	databaseURL: "https://chgi-8a982.firebaseio.com",
	projectId: "chgi-8a982",
	storageBucket: "chgi-8a982.appspot.com",
	messagingSenderId: "993866309968",
	appId: "1:993866309968:web:e6b53222c7c1ef042ccc55",
	measurementId: "G-SE59E4Z0TK"
};
